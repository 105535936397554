@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@100;300;400;600;900&display=swap');

*, *:before, *:after {
  box-sizing: content-box;
}

body {
  background-color: black;
  background-image: url('./images/background.svg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  font-family: Oswald, serif;
  margin: 0;
  padding: 0;
  border: 0;
}

.container {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-height: 40rem;
  text-align: center;
  background-color:rgba(0, 0, 0, 0.5);
  max-width: 400px;
  padding: 1rem 3rem;
  border-radius: 20%;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5);
}

.logo {
  display: block;
  filter: drop-shadow(0px 0px 15px #5271ff);
  width: 40%;
  margin: 0 auto;
}

.name {
  color: #fff;
  font-size: 4rem;
  font-weight: 300;
  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #5271ff,
    0 0 42px #5271ff;
  margin: 0.5rem 0;
  text-transform: uppercase;
}

.about {
  color: white;
  font-size: 2rem;
  font-weight: 400;
  text-shadow: 1px 1px 15px rgba(255, 255, 255, 0.3);
  margin: 0.5rem 0;
}

a {
  display: flex;
  color: white;
  font-style: normal;
  text-decoration: none;
  justify-content: center;
}

a > img {
  display: block;
  max-width: 2rem;
  margin: auto 0.5rem;
}

a > div {
  display: block;
  margin: auto 0.5rem;
  text-transform: uppercase;
  font-size: 1.5rem;
  text-align: center;
}

.contact-methods {
  margin: 2rem auto;
}

.contact-methods-list {
  list-style: None;
  margin: 0 auto;
  padding: 0;
}

.contact-item {
  color: white;
  padding: 0.5rem 0;
}
