@import "https://fonts.googleapis.com/css2?family=Oswald:wght@100;300;400;600;900&display=swap";
*, :before, :after {
  box-sizing: content-box;
}

body {
  height: 100vh;
  background-color: #000;
  background-image: url("background.315776d3.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: Oswald, serif;
  overflow: hidden;
}

.container {
  max-height: 40rem;
  text-align: center;
  max-width: 400px;
  background-color: #00000080;
  border-radius: 20%;
  margin: auto;
  padding: 1rem 3rem;
  position: absolute;
  inset: 0;
  box-shadow: 0 0 5px 5px #00000080;
}

.logo {
  filter: drop-shadow(0 0 15px #5271ff);
  width: 40%;
  margin: 0 auto;
  display: block;
}

.name {
  color: #fff;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #5271ff, 0 0 42px #5271ff;
  text-transform: uppercase;
  margin: .5rem 0;
  font-size: 4rem;
  font-weight: 300;
}

.about {
  color: #fff;
  text-shadow: 1px 1px 15px #ffffff4d;
  margin: .5rem 0;
  font-size: 2rem;
  font-weight: 400;
}

a {
  color: #fff;
  justify-content: center;
  font-style: normal;
  text-decoration: none;
  display: flex;
}

a > img {
  max-width: 2rem;
  margin: auto .5rem;
  display: block;
}

a > div {
  text-transform: uppercase;
  text-align: center;
  margin: auto .5rem;
  font-size: 1.5rem;
  display: block;
}

.contact-methods {
  margin: 2rem auto;
}

.contact-methods-list {
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.contact-item {
  color: #fff;
  padding: .5rem 0;
}

/*# sourceMappingURL=index.cf7a7b9b.css.map */
